// src/modules/auth/frontend/components/ForgotPasswordForm.js

import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { sendPasswordResetEmail } from '../services/authApi';
import {
    Button,
    TextField,
    Typography,
    Container,
    Box,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { tykologAuth } from '../../../../modules/Logs/LogSystem';

const validationSchema = Yup.object({
  email: Yup.string().email('Невірний формат емейлу').required('Емейл обов\'язковий')
});

const ForgotPasswordForm = ({onClose}) => {
  const [message, setMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await sendPasswordResetEmail(values.email);
        setMessage('Інструкції з відновлення пароля надіслано на ваш емейл.');
        tykologAuth('sendPasswordResetEmail', { email: values.email });
      } catch (error) {
        setMessage('Сталася помилка. Спробуйте ще раз.');
        tykologAuth('sendPasswordResetEmail - error', { email: values.email });
      }
    }
  });

  return (
    <Container maxWidth="xs">
        <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </Box>

      <Typography variant="h4" gutterBottom>
        Відновлення пароля
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          id="email"
          name="email"
          label="Емейл"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        {message && <Typography color="primary">{message}</Typography>}
        <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }}>
          Відновити пароль
        </Button>
      </form>
    </Container>
  );
};

export default ForgotPasswordForm;
