import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../contexts/AuthContext';
import { loginUser } from '../services/authApi';
import {
  Button,
  TextField,
  Typography,
  Container,
  IconButton,
  InputAdornment,
  Link,
  Box
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';

// Схема валідації
const validationSchema = Yup.object({
  email: Yup.string().email('Невірний формат емейлу').required('Емейл обов\'язковий'),
  password: Yup.string().required('Пароль обов\'язковий')
});

const LoginForm = ({ onClose, onSuccess, auth, toRegisterForm, toForgotForm, onError }) => {
    const { setAuth } = useAuth(); // Доступ до контексту аутентифікації
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate(); // Навігація після логіну
    const location = useLocation(); // Інформація про попередню сторінку
    const from = location.state?.from?.pathname || '/'; // Попередня сторінка або домашня

    // Налаштування форми Formik
    const formik = useFormik({
        initialValues: {
        email: '',
        password: ''
        },
        validationSchema,
        onSubmit: async (values) => {
        try {
            console.log('Form submit triggered');
            const response = await loginUser(values.email, values.password);
            console.log('Successful login', response.data);

            // Оновлення контексту аутентифікації
            setAuth({token: response.data.token, email: values.email});

            // Виклик onSuccess після успішного логіну
            if (onSuccess) {
            onSuccess(values.email);
            }

            // Переходимо на попередню сторінку
            navigate(from, { replace: true });
        } catch (err) {
            console.log('Error during login', err);
            setError(err.message);
            console.log('Error2 during login', err.message);
            onError(err.message);
        }
        }
    });

    useEffect(() => {
      console.log('Error state updated:', error);
  }, [error]);
    // Очищення помилки при зміні значень форми
    // useEffect(() => {
    //     setError('');
    //     console.log("setError: to null");
    // }, [formik.values]);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();

    return (
        <Container maxWidth="xs">
        {auth && (
            <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onClose}>
                <CloseIcon />
            </IconButton>
            </Box>
        )}

        <Typography variant="h4" gutterBottom>
            Вхід
        </Typography>
        <form onSubmit={formik.handleSubmit} >
            <TextField
            fullWidth
            margin="normal"
            id="email"
            name="email"
            label="Емейл"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
            fullWidth
            margin="normal"
            id="password"
            name="password"
            label="Пароль"
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                    aria-label="Переключити видимість пароля"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
                )
            }}
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }}>
            Увійти
            </Button>

            {/* Посилання на відновлення пароля */}
            <Box mt={2} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="body2">
                Немає акаунту?{' '}
                <Button onClick={toRegisterForm}>
                Зареєструватися
                </Button>
            </Typography>
            <Typography variant="body2">
                Забули пароль?{' '}
                <Button onClick={toForgotForm}>
                    Відновити пароль
                </Button>
            </Typography>
            </Box>
        </form>
        </Container>
    );
};

export default LoginForm;
