import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container } from '@mui/material';
import PrivatePage from '../components/PrivatePage';
import CRUD_ClubProjects_multilang from '../../../../pages/RC_Cherkasy/rotariansBD/adminsPages/CRUD_ClubProjects_multilang';

const adminUserDefaultFields = [
  { field: 'tags', defValue: 'rec_ukraine' },
  { field: 'club_type', defValue: 'ReC' },
  { field: 'club_name', defValue: 'Ukraine' },
  { field: 'club_name_ukr', defValue: 'Україна' },
]
const adminProjectDefaultFields = [
  { field: 'tags', defValue: 'rec_ukraine' },
  { field: 'rtype', defValue: 'ReC' },
  { field: 'rclub', defValue: 'Ukraine' },
]
const adminProjectDefaultFields_sing = [
  { field: 'tags', defValue: 'singapore' },
  { field: 'rtype', defValue: 'ReC' },
  { field: 'rclub', defValue: 'Ukraine' },
]

const site_id = 3;
const adminSitePageDefaultFields = [
  { field: 'site_id', defValue: site_id },
]
const sitePageColumns = [
  { field: 'site_id',     headerName: 'site_id', type: 'lineedit', default: site_id, tablehided: 'true' },
  { field: 'url_path',    headerName: 'url_path', type: 'lineedit', default: '' },
  { field: 'button_title',headerName: 'button_title', type: 'lineedit', default: '', tablehided: 'true' },
  { field: 'lang',        headerName: 'lang', type: 'lineedit', default: '', tablehided: 'true' },
  { field: 'page_json',   headerName: 'page_json:', type: 'json', default: '', tablehided: 'true' },
];

const mprojectColumns = [
  { field: 'id', headerName: 'id', type: 'id', default: '' },
  //{ field: 'defaultlang', headerName: 'defaultlang:', type: 'lineedit', default: '' },
  //{ field: 'arrlanguage', headerName: 'arrlanguage:', type: 'lineedit', default: '' },
  { field: 'tags', headerName: 'tags:', type: 'checkbox', default: '' },
];
const projectColumns = [
  { field: 'project_id', headerName: 'id', type: 'readonly', default: '' },
  { field: 'mproj_id', headerName: 'mproj_id', type: 'readonly', default: '' },
  { field: 'currlang', headerName: 'currlang', type: 'readonly', default: '' },
  { field: 'rtype', headerName: 'rtype', type: 'forcopy', default: '' },
  { field: 'rclub', headerName: 'rclub', type: 'forcopy', default: '' },
  { field: 'tags', headerName: 'tags', type: 'forcopy', default: '' },
  //{ field: 'youtube:public', headerName: 'Public:', type: 'advlineedit', default: 'false', tablehided: 'true' },

  { field: 'category', headerName: 'Rotary Area of Focus:', type: 'checkbox', default: '' },
  { field: 'category2', headerName: 'Project category:', type: 'checkbox', default: '' },
  { field: 'status', headerName: 'Realization Stage:', type: 'combo', default: '' },
  { field: 'budget', headerName: 'Budget:', type: 'combo', default: '' },
  //Organizing Clubs:
  { field: 'avatarimg', headerName: 'Primary photo', type: 'img', default: '', tablehided: 'true' },
  { field: 'imgs', headerName: 'Additional photo list:', type: 'multilineedit', default: '', tablehided: 'true' },
  { field: 'title', headerName: 'Title:', type: 'lineedit', default: '' },

  { field: 'youtube:fdesc', headerName: 'Short Description:', type: 'advmultilineedit', default: '', tablehided: 'true', tablehided: 'true' },
  { field: 'description', headerName: 'Full Description:', type: 'multilineedit', default: '', tablehided: 'true', tablehided: 'true' },
  //Rotary Showcase and Marching Grants:
  //Article:
  //Author:
  { field: 'youtube:donors', headerName: 'Donors:', type: 'advlineedit', default: '', tablehided: 'true' },
  { field: 'youtube:partners', headerName: 'Partners:', type: 'advlineedit', default: '', tablehided: 'true' },
  
  { field: 'genlink', headerName: 'Links of project: website, fb, other (by coma)', type: 'lineedit', default: '', tablehided: 'true' },
  { field: 'docs', headerName: 'Links of project documents, presentations, other (by coma)', type: 'lineedit', default: '', tablehided: 'true' },
  
  { field: 'primarycontact', headerName: 'Shared primarycontact: First name and Sirname', type: 'lineedit', default: '', tablehided: 'true' },
  { field: 'email', headerName: 'Shared contact: Email', type: 'lineedit', default: '', tablehided: 'true' },
  { field: 'phone', headerName: 'Shared contact: Phone', type: 'lineedit', default: '', tablehided: 'true' },
  { field: 'fb', headerName: 'Shared contact: Other social platforms: youtube, fb, insta (by coma)', type: 'lineedit', default: '', tablehided: 'true' },

  
  { field: 'youtube:totalbudget', headerName: 'Total Budget:', type: 'advlineedit', default: '', tablehided: 'true' },
  { field: 'youtube:currentbudget', headerName: 'Current Budget:', type: 'advlineedit', default: '', tablehided: 'true' },
  //{ field: 'youtube:publicd2232', headerName: 'Public to D2232:', type: 'advlineedit', default: false, tablehided: 'true' },
];

const tags = 'd2232';

const MyAdiminProj = () => {
  const { idclub } = useParams();

  return (
    <PrivatePage>
      <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Welcome to the MyAdiminProj
      </Typography>
      <Typography variant="body1">
        This page is only accessible to authenticated users. You have successfully logged in!
      </Typography>
      </Container>

      tags={tags}<br/>
      <CRUD_ClubProjects_multilang 
        title={'Administration for Club Projects'}
        tags={tags} 
        adminProjectDefaultFields={adminProjectDefaultFields} 
        mprojectColumns={mprojectColumns}
        projectColumns={projectColumns} 
      />
    </PrivatePage>
  );
};

export default MyAdiminProj;
