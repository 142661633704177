import React, { useState, useEffect } from 'react';

import {  Paper} from '@mui/material';

import TDataColumnsTable from '../../components/TDataColumnsTable';

import {getAllLogs} from '../../api_sequelize/api_logs';

const userColumns = [
    { field: 'id', headerName: 'id', type: 'lineedit' },
    { field: 'device_id', headerName: 'device_id' },
    { field: 'msg', headerName: 'msg' },
    { field: 'proj', headerName: 'proj'},
    { field: 'func', headerName: 'func'},
    { field: 'type', headerName: 'type'},
    { field: 'tags', headerName: 'tags' },
    { field: 'advData:ermmsg', headerName: 'advData' },
    { field: 'createdAt', headerName: 'createdAt' },
];

const LogsMyPage2 = () => {
    const [data, setData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const d = await getAllLogs();
                setData(d.reverse());
            } catch (error) {
                
                console.log("LogsMyPage error="+error);
            }
        };
        fetchData();
        
    }, [])

  const handleSubmit = async () => {
  }

  return (
    <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
        LogsMyPage
        <TDataColumnsTable data={data} userColumns={userColumns} />
    </Paper>
  );
};

export default LogsMyPage2;
