import React from 'react';
import { Container, Paper, Box, Typography } from '@mui/material';
import ResetPasswordForm from '../components/ResetPasswordForm';
import PrivatePage from '../components/PrivatePage';
import AuthMenu from '../components/AuthMenu';
import Footer from '../components/Footer';

const ResetPasswordPage = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
    <AuthMenu activeForm='login'/>

    <div style={{ flex: 1 }}>
      <Container maxWidth="sm">
        <Paper sx={{ mt: "40px", p: "24px", backgroundColor: "white" }}>

          <Box mb={2}>
          <Typography variant="h4" component="h1" align="center">
              Скинути пароль
          </Typography>
          </Box>
          <ResetPasswordForm />
        </Paper>
    </Container>
    </div>
    <Footer/>
    </div>

  );
};

export default ResetPasswordPage;
