import React, { useState, useEffect } from 'react';
import { Typography, Container, Paper } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import AuthMenu from './AuthMenu';
import Footer from './Footer';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import {tykologAuth} from '../../../../modules/Logs/LogSystem';

const PrivatePage = ({children}) => {
    const { auth, setAuth } = useAuth();
    const [activeForm, setActiveForm] = useState(null); // 'login', 'register', or null

    useEffect(() => {
        setActiveForm(auth ? null : 'login');
    }, [auth]);

    // Логін, реєстрація, розлогінення
    const handleLogin = () => {
        setActiveForm('login');
    };

    const handleRegister = () => {
        setActiveForm('register');
    };

    const handleForgot = () => {
        setActiveForm('forgotpwd');
    };

    const handleLogout = () => {
        setAuth(null);
        tykologAuth('handleLogout');
    };

    const handleCancel = () => {
        setActiveForm(null);
    };

    const handleLoginSuccess = (email) => {
        setActiveForm(null);
        tykologAuth('handleLoginSuccess', {email});
    };

    const onError = (ermmsg) => {
        tykologAuth('handleLoginError', {ermmsg});
    };
    
    const onRegisterError = (ermmsg) => {
        tykologAuth('handleRegisterError', {ermmsg});
    };

    // Рендер форми залежно від активної
    const renderForm = () => {
        if (activeForm === 'login') {
            return <LoginForm onClose={handleCancel} onSuccess={handleLoginSuccess} auth={auth} toRegisterForm={handleRegister} toForgotForm={handleForgot} onError={onError}/>;
        }
        if (activeForm === 'register') {
            return <RegisterForm onClose={handleCancel} auth={auth} toLoginForm={handleLogin} onError={onRegisterError} />;
        }
        if (activeForm === 'forgotpwd') {
            return <ForgotPasswordForm onClose={handleCancel} />;
        }
        return null;
    };

    return (
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
        {/* activeForm={activeForm} */}
        {/* Використання компонента AuthMenu */}
        <AuthMenu activeForm={activeForm} auth={auth} onLogin={handleLogin} onRegister={handleRegister} onLogout={handleLogout} />
    
        {/* Основний контент сторінки */}
        <div style={{ flex: 1 }}>
          {/* Відображення форми логіну або реєстрації */}
          {activeForm && <Container maxWidth="sm">
            <Paper sx={{ mt: "40px", p: "24px", backgroundColor: "white" }}>
              {renderForm()}
            </Paper>
          </Container>}
    
          {!activeForm && auth && <>{children}</>}
    
          {/* Якщо користувач не авторизований або показуємо логін */}
          {(!auth || activeForm === "login") && !activeForm && (
            <Container maxWidth="sm">
              <LoginForm
                onClose={handleCancel}
                onSuccess={handleLoginSuccess}
                auth={auth}
                toRegisterForm={handleRegister}
              />
            </Container>
          )}
        </div>
    
        {/* Футер */}
        <Footer/>
      </div>
    );
    
};

export default PrivatePage;
