import { Box, Typography, Link, Container } from "@mui/material";

const Footer = () => {
  return (
    <Box 
      component="footer" 
      sx={{ 
        backgroundColor: "gray", 
        py: 0, 
        borderTop: "1px solid #e0e0e0" 
      }}
    >
      <Container maxWidth="lg">
        {/* <Box 
          sx={{ 
            display: "flex", 
            justifyContent: "center", 
            gap: 2, 
            mt: 1 
          }}
        >
          <Link  color="white" underline="hover">
            Contact Us
          </Link>
          <Link  color="white" underline="hover">
            Privacy Policy
          </Link>
          <Link  color="white" underline="hover">
            Terms of Use
          </Link>
        </Box> */}
        <Box 
          sx={{ 
            display: "flex", 
            justifyContent: "center", 
            gap: 2, 
            m: 1 
          }}
        >
          <Link href="https://rotaryd2232ukraine.org" target="_blank" rel="noopener" color="white" underline="hover">
            Official website
          </Link>
          <Link href="https://www.facebook.com/RotaryInUkraine" target="_blank" rel="noopener" color="white" underline="hover">
            Facebook
          </Link>
          <Link href="https://www.instagram.com/rotary.ukraine/" target="_blank" rel="noopener" color="white" underline="hover">
            Instagram
          </Link>
        </Box>
        <Typography variant="body2" color="lightgray" align="center" gutterBottom>
          © {new Date().getFullYear()} Rotary in Ukraine. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
