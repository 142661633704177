import React, { useState, useEffect } from 'react';

import {  Paper} from '@mui/material';

import TDataColumnsTable from '../../components/TDataColumnsTable';

import {getalllogs} from '../../api/BdLogging/BdLogging';

const userColumns = [
    { field: 'log_id', headerName: 'log_id', type: 'lineedit', default: '' },
    { field: 'timestamp', headerName: 'timestamp', type: 'lineedit', default: '' },
    { field: 'project', headerName: 'project', type: 'lineedit', default: '' },
    { field: 'func', headerName: 'func', type: 'lineedit', default: '' },
    { field: 'status', headerName: 'status', type: 'lineedit', default: '' },
    { field: 'message', headerName: 'message', type: 'multilineedit', default: '' },
    //{ field: 'debud', headerName: 'debud', type: 'multilineedit', default: '' },
    { field: 'user', headerName: 'user', type: 'lineedit', default: '' },
    //{ field: 'description', headerName: 'description', type: 'lineedit', default: '' },
];

const LogsMyPage = () => {
    const [data, setData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const d = await getalllogs();
                setData(d);
            } catch (error) {
                
                console.log("LogsMyPage error="+error);
            }
        };
        fetchData();
        
    }, [])

  const handleSubmit = async () => {
  }

  return (
    <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
        LogsMyPage
        <TDataColumnsTable data={data} userColumns={userColumns} />
    </Paper>
  );
};

export default LogsMyPage;
