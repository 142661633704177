import React, { useState } from 'react';
import { Box, AppBar, Toolbar, IconButton, Avatar, Menu, MenuItem, Typography, Tooltip } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Divider from '@mui/material/Divider';

const AuthMenu = ({ activeForm, auth, onLogin, onRegister, onLogout }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  // Відкриття меню
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  // Закриття меню
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <AppBar position="static">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Логотип */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src="https://7fc65ea596c38701.cdn.express/secure/UdgAkc7xGK1M_yWGF9zFFA/clubsitecreator/D2232/Logo/1707318206588-895329449.png"
            alt="Logo"
            style={{ height: "40px", marginRight: "16px" }}
          />
        </Box>
  
        {/* Текст у центрі */}
        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
          My Rotary in UA
        </Typography>
  
        {/* Аватар для відкриття меню */}
        <Tooltip title={activeForm ? "Ви не залогінені" : `Ви увійшли як: ${auth?.email}`}>
        <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
          <Avatar>
            {activeForm ? <PersonOutlineIcon /> : auth?.email.slice(0, 2).toUpperCase()}
          </Avatar>
        </IconButton>
        </Tooltip>
  
        {/* Спадне меню */}
        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          PaperProps={{ style: { width: "200px" } }}
        >
          {auth && auth.email && <MenuItem disabled>
            {auth.email}
          </MenuItem>}
          {auth && auth.email && <Divider/>}
          {activeForm && activeForm !== 'login' && <MenuItem onClick={() => { handleMenuClose(); onLogin(); }}>
            Логін
          </MenuItem>}
          {activeForm && activeForm !== 'register' && <MenuItem onClick={() => { handleMenuClose(); onRegister(); }}>
            Реєстрація
          </MenuItem>}
          {!activeForm && <MenuItem onClick={() => { handleMenuClose(); onLogout(); }}>
            Вийти
          </MenuItem>}
        </Menu>
      </Toolbar>
    </AppBar>
  );
  
};

export default AuthMenu;
